import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import imageUrl from "../../content/assets/underConstruction.jpg"

const BlogIndex = ({ location }) => {
  return (
    <Layout location={location} title="Legitnik">
      <div style={{ textAlign: "center" }}> 
        <img src={imageUrl} alt="Under Construction" style={{ maxWidth: '100%' }}></img>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
